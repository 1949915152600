body {
  background-color: #FAFAFA;
  font-size: 16px;
}

#root {
  height: 100%;
}

.ui.fixed.menu {


  background: #FFFFFF;
  box-shadow: 0 10px 30px 0 rgba(0,0,0,0.05);
  border-radius: 0 0 20px 20px; 

}

.ui.inverted.menu .item, .ui.inverted.menu .item>a:not(.ui) {
  color: #3F3F3F
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg,
    rgb(239, 65, 54) 0%,
    rgb(247, 148, 29) 100%
  ) !important;
  height: 100vh;
}

.masthead h1.ui.header {
  font-size: 4em;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-calendar {
  width: 100%;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, .15);
}

@font-face {
  font-family: 'NormalTextFont';

  src:  local('Avenir-Medium'), url(../../fonts/Avenir-Medium.ttf) format('truetype'); 

   /* src: url(../..//fonts/Avenir-Medium.ttf) format('truetype');  */
}

@font-face {
  font-family: 'TitleTextFont';

  src: local('Avenir-Heavy'), url(../../fonts/Avenir-Heavy.ttf) format('truetype'); 

   /* src: url(../../fonts/Avenir-Heavy.ttf) format('truetype');  */
}

@font-face {
  font-family: 'ItalicTextFont';

  src:  local('Avenir-BookOblique'), url(../../fonts/Avenir-BookOblique.ttf) format('truetype'); 

   /* src: url(../../fonts/Avenir-BookOblique.ttf) format('truetype');  */
}

body {
  font-family: NormalTextFont, Arial, Helvetica, sans-serif;
  font-size: 15px;
}

.normalTextFont {
  font-family: NormalTextFont, Arial, Helvetica, sans-serif;
}

.titleTextFont {
  font-family: TitleTextFont, Arial, Helvetica, sans-serif;

}

.italicTextFont {
  font-family: ItalicTextFont, Arial, Helvetica, sans-serif;

}

.ui.segments, .topicMenu.ui.large.vertical.menu, .ui.attached.header, .customCard {
  /* border: none;
  border-radius: .35rem; */
  border: none;
  box-shadow: 0 10px 50px 0 rgba(0,0,0,0.05);
  border-radius: 10px;
}

.topicMenu.ui.large.vertical.menu {
  background-color: #3F3F3F;
  text-align: left;
}

.topicMenu.ui.large.vertical.menu .item {

  color: #D8D8D8;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;  
  font-size: 15px;

}



.sidebar {
  width: 150px;
  min-width: 150px;
}

.ui.segment.pushable {
  border: none;
}

.ui.inverted.menu.sidebar {
  background-color: #3F3F3F;
}

.ui.thin.left.sidebar, .ui.thin.right.sidebar {
  width: 200px;
}

.logoWrapper {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  padding-left: 9px;
  padding-top: 8px;
  background: #FFFFFF;
  box-shadow: 0 8px 20px 0 rgba(0,0,0,0.15);
}


.ui.inverted.menu .item:before {
  background: none;
}

.topicMenu.ui.large.vertical.menu .active.item {
  color: #fff;
}



.topicStatsList {
  background-image: linear-gradient(
    135deg,
    rgb(239, 65, 54) 0%,
    rgb(247, 148, 29) 100%
  ) !important;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.topicStat {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;

}

.topicStatKey {
  color: white;
  font-size: 13px;
}

.topicStatValue {
  padding-top: 4px;
  color: white;
  font-size: 18px;
}

.settingsDropdown.ui.button, .settingsDropdown.ui.button:hover {
  background-image: url("/assets/settings.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 22px 22px;
  background-color: #3F3F3F;
  
}

.settingsDropdown.button.ui.dropdown>.dropdown.icon:before {
  content: "";
}


.ui.bottom.left.pointing.dropdown>.menu.settingsDropdownMenu {
  margin-left: 14px;
}

.ui.dropdown .menu>.header {
  font-family: TitleTextFont, Arial, Helvetica, sans-serif;

}

.gradientBackground {
  background-image: linear-gradient(
    135deg,
    rgb(239, 65, 54) 0%,
    rgb(247, 148, 29) 100%
  ) !important;
}

.ui.button.submitButton, body > div.ui.page.modals.dimmer.transition.visible.active > div > div.actions > button.ui.primary.button {

  background-image: linear-gradient(
    135deg,
    rgb(239, 65, 54) 0%,
    rgb(247, 148, 29) 100%
  ) !important;
  margin-top: 7px;
}

.ui.mini.modal>.header:not(.ui) {

  font-family: TitleTextFont, Arial, Helvetica, sans-serif;
  text-align: center;

}

.ui.basic.red.label {
  border: none;
  padding-left: 0px;
  font-family: NormalTextFont, Arial, Helvetica, sans-serif;
  color: #EF4136;
  
}

.ui.form input.textInput {
}


.ui.dropdown .menu>.item>.description {

  width: 120px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ui.dropdown>.dropdown.icon {
  margin-left: 8px;
}

.ui.modal.subscribeModalWrapper {
  background-image: linear-gradient(
    135deg,
    rgb(239, 65, 54) 0%,
    rgb(247, 148, 29) 100%
  ) !important;

  border-radius: 10px;
}

@media only screen and (max-width: 767px) {
  .ui.container.sidebarVisible {
    width: auto!important;
      /* margin-left: 1em!important;
      margin-right: 1em!important; */
  }

}

@media only screen and (max-width: 991px) and (min-width: 768px) {
  .ui.container.sidebarVisible {
    padding-left: 260px;

      width: 723px;
      /* margin-left: auto!important;
      margin-right: auto!important; */
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .ui.container.sidebarVisible {
    padding-left: 260px;
      width: 933px;
      /* margin-left: auto!important;
      margin-right: auto!important; */
  }
}

@media only screen and (min-width: 1200px) {
  .ui.container.sidebarVisible {
      padding-left: 260px;
      width: 1127px;
      /* margin-left: auto!important;
      margin-right: auto!important; */
  }
}


.ui.menu.topicListHeader .item {
  padding-left: 14.857px;
  padding-right: 14.857px;

}


.ui.segment.questionSegment {
  font-size: 12px;
  border-top: 0;
  padding-top: 0px;
}


div [class*="right floated"] {
  float: right;
   margin-right: 0.25em;
} 



.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before, .ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before {
 background-color: black!important;
}


.ui.compact.menu.reviewTypeMenu {
  border-radius: 20px;
  height: 40px;

}

.ui.compact.menu.reviewTypeMenu .item:last-child {
  border-radius: 0 20px 20px 0;
}

.ui.compact.menu.reviewTypeMenu .item:first-child {
  border-radius: 20px 0 0 20px;
}


.configureReviewRow {
  padding-left: 21px;
  padding-right: 21px;
  /* border-bottom-color: #EEEFF0;
  border-bottom-width: 1px; */
  border-bottom: 1px solid #EEEFF0;
  height: 76px;
}

.ui.menu.reviewTypeMenu .active.item {
  background: black;
  color: white;
  font-weight: 400;
  box-shadow: none;
}
.ui.inverted.menu.reviewNavMenu a.item:hover, .ui.inverted.menu.searchNavMenu a.item:hover {
  color: #3F3F3F;

}
.configureReviewRow .ui.selection.dropdown>.dropdown.icon {
  padding-left: 0px;
  padding-right: 2px;

}

.configureReviewRow .ui.selection.dropdown {
  padding-left: 6px;
  padding-right: 20px;

}

.configureReviewRow .ui.selection.active.dropdown .menu {
  border: 0;
  text-align: right;
}

.configureReviewRow  .ui.selection.dropdown .menu>.item {
  text-align: right;
}

 .ui.button.reviewButton {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  box-shadow: 0 10px 50px 0 rgba(0,0,0,0.05);
  padding: 0px;
  margin: 0px;
}

/* .noteSelect, .noteSelect .dropdown.icon {
  padding-top: 20px!important;
  padding-bottom: 0px!important;
  height: 70px;
  max-height: 70px;
  min-height: 70px;
} */


.ui.grid>.column:not(.row), .ui.grid>.row>.column {
  padding-left: 20px;
  padding-right: 20px;

}


.flip-container {
  perspective: 1000px;
}

.flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flip-container,
.front,
.back {
  height: 460px;
  width: 100%;
}

@media only screen and (max-width: 767px) {

  .flip-container,
  .front,
  .back {
    height: 320px;
    width: 100%;
  }
  
}

.flashcardWithFixedHeight {
  height: 460px;

}

@media only screen and (max-width: 767px) {

  .flashcardWithFixedHeight {
    height: 320px;
  }
}

.flipper {
  transition: 0.3s;
  transform-style: preserve-3d;
  position: relative;
}
.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.front {
  z-index: 2;
  transform: rotateY(0deg);
}
.back {
  transform: rotateY(180deg);
}

.flip-container .ui.grid {
  margin: 0;
}

.fitdm {
  text-decoration: underline;
  text-decoration-color: #3F3F3F;
  text-decoration-thickness: 2px;
  text-underline-position: under;
}

.fitbb, .fitbf {
  text-decoration: underline;
  text-decoration-color: #EF4136;
  text-decoration-thickness: 2px;
  text-underline-position: under;
}

.fitbf {
  color: #FFF;
}

.fitbf::selection {
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

.fitbb {
  color: #EF4136;
}

.reviewQuestionText {
  text-align: center;
}

.flashcard {
  font-size: 17px;
  line-height: 25px;
}

.progressBarWrapper {
  display: flex;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  margin-bottom: 30px;
}

.progressBar {

    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    transition: width .6s ease;
}

.progressBarWrapper .progressBar:last-child {
  border-radius: 0 10px 10px 0;
}

.progressBarWrapper .progressBar:first-child {
  border-radius: 10px 0 0 10px;
}

.ui.button.secondaryButton {
  background: #FFFFFF;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10);
  color: #EF4136;
}

.ui.button.reviewCompletePrimaryButton {
  background: #FFFFFF;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.10);
  border-radius: 100px;
  color: #EF4136;
}


.ui.modal.subscribeModalWrapper .content {
background-image: linear-gradient(
  135deg,
  rgb(239, 65, 54) 0%,
  rgb(247, 148, 29) 100%
) !important;
color: #fff;
border-radius: 10px;
}




.StripeElement {
  width: 100%;
  padding: 11px 20px 11px 20px;
  font-family: NormalTextFont, Arial, Helvetica, sans-serif;

  background: #FAFAFA;
  border: 1px solid #F1F1F1;
  border-radius: 4px;
}

/* .Toastify__toast-container {
  border-radius: 20px;
  padding: 20px;

} */



@media only screen and (max-width: 767px) {

  .Toastify__toast-container {
    padding: 16px;
    margin-bottom: 0px;
  }

  .Toastify__toast {
    margin-bottom: 16px;
  }

}

.Toastify__toast {
  border-radius: 10px;
  padding: 20px;
  font-family: NormalTextFont, Arial, Helvetica, sans-serif;

}

.Toastify__toast--success {
  background-color: #3CBC5F;
}

.Toastify__toast--info {
  background-color: #3F3F3F;
  color: #FFF;

}

.Toastify__toast--error {
  background-color: #EF4136;
}

.ui.loader.portalLoader {
  display: inline-block;
}

.ui.loader.portalLoader:after, .ui.loader.portalLoader:before {
  margin-top: 5px;
 width: 19px;
 height: 19px;
}


.animated-enter  {
  opacity: 0;
  /* transform: translateX(-100%) translateY(-100%); */
  transform: scale(0.5);

}
.animated-enter-active  {
  opacity: 1;
  transform: translateX(0%);
}
.animated-exit  {
  opacity: 1;
  transform: translateX(0%);
}
.recalled .animated-exit-active  {
  opacity: 0;
  transform: translateX(100%);
}

.forgotten .animated-exit-active  {
  opacity: 0;
  transform: translateX(-100%);
}

.undo .animated-exit-active  {
  opacity: 0;
  transform: scale(0.5);
}

.animated-enter-active,
.animated-exit-active {
  transition: opacity 300ms, transform 300ms;
}

.constrainedColumn {
  max-width: 550px;
  /* max-width: 300px; */

}

.ui.grid>.row.configureReviewRow>.column {
  padding-right: 16px;
  padding-left: 16px;
}



@media only screen and (max-width: 885px) {

  .reviewSidebarButton {
    display: none !important;
  }

}

.bodyInlineImage {
  width: auto;
  height: 13px;
}


.ui.button.editorMenuButton {
  

}

.ui.button.editorButton {
  background-color: #F7F7F7;
}
.ui.active.button.editorButton {
  background-color: #E0E0E0;
}

.ui.button.editorSaveButton {
  background: #E1E1E1;
  color: rgba(63,63,63, 0.2);
}

.ui.button.editorSaveButton.active {
  
  background-image: linear-gradient(
    135deg,
    rgb(239, 65, 54) 0%,
    rgb(247, 148, 29) 100%
  ) !important;
  color: #FFF;
}


html, body, .pushable {
  height: 100%;
}


/* width */
::-webkit-scrollbar {
  width: 8px !important;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey !important; 
  border-radius: 10px !important; */
  /* background: #FAFAFA !important;  */
  background: rgba(0, 0, 0, 0.0) !important;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #3F3F3F !important;  */
  border-radius: 4px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {

}

.searchResultItem:last-child {
  padding-bottom: 16px;
}

.noteListItem:first-child  .ui.segments {

  /* margin-bottom: 0px !important; */
}

.sharedNoteList .noteListItem:first-child {

  padding-bottom: 8px;
}

.noteListItem:first-child {

  padding-bottom: 0px;
}

.noteListItem:last-child {

  padding-top: 8px;
}

p {
  margin: 0 0 0em ;
}



.ui.modal.editor .content,  .ui.modal.nopadding .content{
  padding: 0px !important;
  border-radius: 30px;
}

.cropper-view-box {

  outline: 1px solid #EF4136 !important;
  outline-color: rgba(239, 65, 54, 0.75)  !important;

}

.cropper-line, 
.cropper-point {
  background-color: #EF4136  !important;
}

.dz:focus {
  outline: -webkit-focus-ring-color auto 0px;
}

.hoverableMenuItem:hover {
  background: rgba(0,0,0,.05)!important;
  cursor: pointer;
}

.ui.dropdown.topicFilterDropdown > .text {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: color 0.2s ease 0s;
  white-space: nowrap;
  max-width: 154px;
}

.ui.dropdown.navBarTitle > .text {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



.navBarTitle {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

@media only screen and (max-width: 767px) {

  .ui.container.sidebarHidden .navBarTitle {
    padding-left: 52px;
  }
  
  .topicDetailsDropdownMenu{
    margin-left: 52px !important;
 }

}

@media only screen and (max-width:831px) and (min-width: 768px) {

  .ui.container.sidebarHidden .navBarTitle {
    padding-left: 32px;
  }

  .topicDetailsDropdownMenu{
    margin-left: 32px !important;
 }
  
}

@media only screen and (max-width: 991px) and (min-width: 832px) {

  .ui.container.sidebarHidden .navBarTitle {
    padding-left: 0px;
  }
  
}


@media only screen and (max-width: 1020px) and (min-width: 992px) {

  .ui.container.sidebarHidden .navBarTitle {
    padding-left: 16px;
  }
  
  .topicDetailsDropdownMenu{
    margin-left: 16px !important;
 }

}

#reviewButton {
  padding-right: 0px;
}

@media only screen and (max-width: 767px) {

  #reviewButton {
    padding-right: 16px;
  }
  
}



.ui.menu .item .description {
  text-align: right;
}


/* .cropper-modal {
  background-color: #F1F1F1 !important;
  opacity: 1.0 !important;
  border: 1px solid #F1F1F1;
} */

.img-container {
  /* Never limit the container height here */
  max-width: 100%;
}

.img-container img {
  /* This is important */
  width: 100%;
}

.ui.inverted.menu.searchNavMenu .input input {
  background: #FAFAFA;
  border: 1px solid #F1F1F1;
  border-radius: 20px;

}

@media only screen and (max-width: 767px) {

  .ui.inline.image.reviewTypePopup {
    display: none;
  }
}

.specialPage p {
  margin-top: 10px;
}


.ui.form input:not([type]):focus, .ui.form input[type=date]:focus, .ui.form input[type=datetime-local]:focus, .ui.form input[type=email]:focus, .ui.form input[type=file]:focus, .ui.form input[type=number]:focus, .ui.form input[type=password]:focus, .ui.form input[type=search]:focus, .ui.form input[type=tel]:focus, .ui.form input[type=text]:focus, .ui.form input[type=time]:focus, .ui.form input[type=url]:focus {
  border-color: #F7941D;
}


.ui.dropdown .menu>.item>.description {
  text-align: right;
}

@media only screen and (max-width: 767px) {

  .hideOnMobile {
    display: none !important;
  }
}

@media only screen and (min-width: 767px) {

  .showOnlyOnMobile {
    display: none !important;
  }
}


.reviewNavBarTitle {
    max-width: 320px;
  }
  @media only screen and (max-width: 767px) {
   
    .reviewNavBarTitle {
      max-width: 180px;
    }
  }
   

.navBarEdgeButton {
  width: 100px;
}
@media only screen and (max-width: 767px) {

  .navBarEdgeButton {
    width: 50px;
  }
}


#overdueNavBarCount {
  left: 48px;
  top: 4px;
}
@media only screen and (max-width: 767px) {

  #overdueNavBarCount {
    left: 42px;
    top: 2px;

  }
}



 .ui.modal>.header {
  padding: 16px !important;
 }

 .reviewStar {
  width: 24px;
  height: 24px;
 }


 .alice-carousel__dots {
  margin: 16px 3px 5px !important;
 }

 alice-carousel__dots-item {
  background-color: #f8f8f8 !important;
 }

 .alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active {
  background-color: #EF4136 !important;

 }

 .editorOCRLoader:before {
  border: .2em solid rgba(0,0,0,.1) !important;

 }

 .editorOCRLoader:after {
  border-color: #767676 transparent transparent !important;
 }

 